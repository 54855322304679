import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, NumberInput } from 'react-admin';

const GamebotEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <TextInput label="Cluster Id" source="clusterId" />
      <NumberInput label="Priority Slots" source="prioritySlots" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export default GamebotEdit;
