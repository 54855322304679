import * as queries from './queries';

import { obj } from '../utils';

function getQueryMethod({ resource, methodName }) {
  if (!obj(queries).exists(resource))
    throw new Error(`No graphql query find for resource: ${resource}`);

  const resourceQueries = queries[resource];
  if (!obj(resourceQueries).exists(methodName))
    throw new Error(`${methodName} method is not found in resource: ${resource}`);

  return resourceQueries[methodName];
}

function factory({ methodName, resource }) {
  return getQueryMethod({ resource, methodName });
}

export default factory;
