import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import { obj } from '../utils';

const sisServerUrl = process.env.REACT_APP_SIS_SERVER_URL;
if (obj(sisServerUrl).isUndefinedOrNull()) throw new Error('sis server url is not defined');

const options = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const link = createHttpLink({
  uri: sisServerUrl,
  fetch: buildAxiosFetch(axios),
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: options,
});

export default client;
