import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const BotServiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <BooleanInput source="enabled" />
      <BooleanInput source="saveLastMessageSlot" />
    </SimpleForm>
  </Edit>
);

export default BotServiceEdit;
