import queryFactory from './queryFactory';

const iMethods = [
  'getList',
  'getOne',
  'update',
  'create',
  'getManyReference',
  'getMany',
  'delete',
  'deleteMany',
];

function genericMethod(methodName) {
  return (resource, options) => {
    const queryMethod = queryFactory({ methodName, resource });
    return queryMethod(options);
  };
}

const dataProvider = iMethods.reduce(
  (obj, method) => ({ ...obj, [method]: genericMethod(method) }),
  {}
);

export default dataProvider;
