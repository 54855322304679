import React from 'react';
import PropTypes from 'prop-types';
import { Edit, SimpleForm, TextInput, Link, Button } from 'react-admin';
import CloudIcon from '@material-ui/icons/Cloud';
import MessageIcon from '@material-ui/icons/Message';
import DeltaDNAIcon from '@material-ui/icons/TableChart';
import AdMobIcon from '@material-ui/icons/Collections';
import AppLovinIcon from '@material-ui/icons/Filter';
import UnityIcon from '@material-ui/icons/FilterNone';

import { obj } from '../../utils';

const GameEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="slug" />
      <TextInput source="name" />
      <CreateEditLinkButton
        targetRes="gameBackend"
        label="Game Backend"
        source="enabledGameBackend"
        icon={CloudIcon}
      />
      <CreateEditLinkButton
        targetRes="botService"
        label="Bot Service"
        source="enabledBotService"
        icon={MessageIcon}
      />
      <CreateEditLinkButton
        targetRes="fbInstantGame"
        label="Fb instant games"
        source="fbInstantGameAppId"
        icon={MessageIcon}
      />
      <CreateEditLinkButton
        targetRes="splitTest"
        label="Split Test"
        source="enabledSplitTest"
        icon={MessageIcon}
      />
      <CreateEditLinkButton
        targetRes="gamebot"
        label="Gamebot"
        source="enabledGamebot"
        icon={MessageIcon}
      />
      <CreateEditLinkButton
        targetRes="deltaDna"
        label="Delta DNA"
        source="deltaDnaProjectId"
        icon={DeltaDNAIcon}
      />
      <CreateEditLinkButton targetRes="adMob" label="AdMob" source="adMobAppId" icon={AdMobIcon} />
      <CreateEditLinkButton
        targetRes="appLovin"
        label="AppLovin"
        source="appLovinAppId"
        icon={AppLovinIcon}
      />
      <CreateEditLinkButton targetRes="unity" label="Unity" source="unityAppId" icon={UnityIcon} />
    </SimpleForm>
  </Edit>
);

const CreateEditLinkButton = ({ icon: Icon, label, targetRes, record, source }) => {
  const action = obj(record[source]).isUndefinedOrNull() ? 'Create' : 'Edit';
  const fullLabel = `${action} ${label}`;
  const to =
    action === 'Edit'
      ? {
          pathname: `/${targetRes}/${record.id}`,
        }
      : {
          pathname: `/${targetRes}/create`,
          state: { record: { gameSlug: record.id } },
        };
  return (
    <Link to={to}>
      <Button label={fullLabel} color="primary" size="large" startIcon={<Icon />} />
    </Link>
  );
};

CreateEditLinkButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  targetRes: PropTypes.string.isRequired,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default GameEdit;
