import React from 'react';
import { Admin, Resource } from 'react-admin';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import FacebookIcon from '@material-ui/icons/Facebook';
import MessageIcon from '@material-ui/icons/Message';
import CloudIcon from '@material-ui/icons/Cloud';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DeltaDNAIcon from '@material-ui/icons/TableChart';
import AdMobIcon from '@material-ui/icons/Collections';
import AppLovinIcon from '@material-ui/icons/Filter';
import UnityIcon from '@material-ui/icons/FilterNone';
import { createTheme, ThemeProvider } from '@material-ui/core';
import dataProvider from './graphql/ra-data-sis-admin';
import GameList from './components/game/GameList';
import GameEdit from './components/game/GameEdit';
import GameCreate from './components/game/GameCreate';
import GameBackendList from './components/gameBackend/GameBackendList';
import GameBackendEdit from './components/gameBackend/GameBackendEdit';
import GameBackendCreate from './components/gameBackend/GameBackendCreate';
import SplitTestList from './components/splitTest/SplitTestList';
import SplitTestEdit from './components/splitTest/SplitTestEdit';
import SplitTestCreate from './components/splitTest/SplitTestCreate';
import GamebotList from './components/gamebot/GamebotList';
import GamebotEdit from './components/gamebot/GamebotEdit';
import GamebotCreate from './components/gamebot/GamebotCreate';
import FbInstantGameList from './components/fbInstantGame/FbInstantgameList';
import FbInstantGameEdit from './components/fbInstantGame/FbInstantGameEdit';
import FbInstantGameCreate from './components/fbInstantGame/FbInstanceGameCreate';
import BotServiceList from './components/botService/BotServiceList';
import BotServiceEdit from './components/botService/BotServiceEdit';
import BotServiceCreate from './components/botService/BotServiceCreate';
import DeltaDnaList from './components/deltaDna/DeltaDnaList';
import DeltaDnaEdit from './components/deltaDna/DeltaDnaEdit';
import DeltaDnaCreate from './components/deltaDna/DeltaDnaCreate';
import AdMobList from './components/adMob/AdMobList';
import AdMobEdit from './components/adMob/AdMobEdit';
import AdMobCreate from './components/adMob/AdMobCreate';
import AppLovinList from './components/appLovin/AppLovinList';
import AppLovinEdit from './components/appLovin/AppLovinEdit';
import AppLovinCreate from './components/appLovin/AppLovinCreate';
import UnityList from './components/unity/UnityList';
import UnityEdit from './components/unity/UnityEdit';
import UnityCreate from './components/unity/UnityCreate';

const baseTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#f5842d',
      light: '#ffbfa2',
      dark: '#ff4e00',
    },
    secondary: {
      main: '#2d5a6b',
      light: '#a5cbd9',
      dark: '#1e3d48',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={baseTheme}>
      <Admin dataProvider={dataProvider} theme={baseTheme}>
        <Resource
          name="game"
          options={{ label: 'Game' }}
          list={GameList}
          edit={GameEdit}
          create={GameCreate}
          icon={SportsEsportsIcon}
        />
        <Resource
          name="gameBackend"
          options={{ label: 'Game Backend' }}
          list={GameBackendList}
          edit={GameBackendEdit}
          create={GameBackendCreate}
          icon={CloudIcon}
        />
        <Resource
          name="fbInstantGame"
          options={{ label: 'FB Instant Game' }}
          list={FbInstantGameList}
          edit={FbInstantGameEdit}
          create={FbInstantGameCreate}
          icon={FacebookIcon}
        />
        <Resource
          name="botService"
          options={{ label: 'Bot Service' }}
          list={BotServiceList}
          edit={BotServiceEdit}
          create={BotServiceCreate}
          icon={MessageIcon}
        />
        <Resource
          name="splitTest"
          options={{ label: 'Split Test Service' }}
          list={SplitTestList}
          edit={SplitTestEdit}
          create={SplitTestCreate}
          icon={CallSplitIcon}
        />
        <Resource
          name="gamebot"
          options={{ label: 'Gamebot' }}
          list={GamebotList}
          edit={GamebotEdit}
          create={GamebotCreate}
          icon={MessageIcon}
        />
        <Resource
          name="deltaDna"
          options={{ label: 'Delta DNA' }}
          list={DeltaDnaList}
          edit={DeltaDnaEdit}
          create={DeltaDnaCreate}
          icon={DeltaDNAIcon}
        />
        <Resource
          name="adMob"
          options={{ label: 'AdMob' }}
          list={AdMobList}
          edit={AdMobEdit}
          create={AdMobCreate}
          icon={AdMobIcon}
        />
        <Resource
          name="appLovin"
          options={{ label: 'AppLovin' }}
          list={AppLovinList}
          edit={AppLovinEdit}
          create={AppLovinCreate}
          icon={AppLovinIcon}
        />
        <Resource
          name="unity"
          options={{ label: 'Unity' }}
          list={UnityList}
          edit={UnityEdit}
          create={UnityCreate}
          icon={UnityIcon}
        />
      </Admin>
    </ThemeProvider>
  );
};

export default App;
