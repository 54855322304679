import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query gamebots {
      gamebots {
        clusterId
        enabled
        gameSlug
        prioritySlots
      }
    }
  `;

  const res = await client.query({ query });
  const gamebots = res.data.gamebots.map((gamebot) => ({
    ...gamebot,
    id: gamebot.gameSlug,
  }));
  const { total, page } = clientPagination({ list: gamebots, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query gamebot($gameSlug: ID!) {
      gamebot(gameSlug: $gameSlug) {
        clusterId
        enabled
        gameSlug
        prioritySlots
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { gamebot } = res.data;
  return { data: { ...gamebot, id: gamebot.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation gamebotUpdate(
      $gameSlug: ID!
      $clusterId: ID!
      $enabled: Boolean!
      $prioritySlots: Int!
    ) {
      updateGamebot(
        gameSlug: $gameSlug
        gamebotData: { clusterId: $clusterId, enabled: $enabled, prioritySlots: $prioritySlots }
      ) {
        clusterId
        enabled
        gameSlug
        prioritySlots
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
      enabled: data.enabled,
      clusterId: data.clusterId,
      prioritySlots: data.prioritySlots,
    },
  });
  const { updateGamebot } = res.data;
  return { data: { ...updateGamebot, id: updateGamebot.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation gamebotCreate(
      $clusterId: ID!
      $enabled: Boolean!
      $gameSlug: ID!
      $prioritySlots: Int!
    ) {
      createGamebot(
        gamebotData: {
          clusterId: $clusterId
          enabled: $enabled
          gameSlug: $gameSlug
          prioritySlots: $prioritySlots
        }
      ) {
        clusterId
        enabled
        gameSlug
        prioritySlots
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      clusterId: data.clusterId,
      enabled: data.enabled ?? false,
      prioritySlots: data.prioritySlots,
    },
  });
  const { createGamebot } = res.data;
  return { data: { ...createGamebot, id: createGamebot.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation gamebotDelete($gameSlug: ID!) {
      deleteGamebot(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
