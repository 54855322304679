import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const GameBackendCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="gameSlug" reference="game" perPage={999}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput label="Cluster Id" source="clusterId" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export default GameBackendCreate;
