import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

const FbInstantGameCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="gameSlug" reference="game" perPage={999}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput label="App Id" source="appId" />
      <TextInput source="appSecret" />
      <TextInput source="namespace" />
      <TextInput source="pageAccessToken" />
      <TextInput label="Page Id" source="pageId" />
      <TextInput label="Data Source Property Id" source="dataSourcePropertyId" />
      <TextInput source="webhookVerifyToken" />
      <ArrayInput label="Marketing Account Id" source="marketingAccountId">
        <SimpleFormIterator inline>
          <TextInput label="Account Id" source="accountId" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default FbInstantGameCreate;
