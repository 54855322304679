import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const DeltaDnaCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="gameSlug" reference="game" perPage={999}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput label="Project Id" source="projectId" />
    </SimpleForm>
  </Create>
);

export default DeltaDnaCreate;
