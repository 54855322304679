import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

const GameList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="slug" />
      <TextField source="name" />
      <BooleanField label="Bot Service" source="enabledBotService" />
      <BooleanField label="Game Backend" source="enabledGameBackend" />
      <BooleanField label="Split Test" source="enabledSplitTest" />
      <BooleanField label="Gamebot" source="enabledGamebot" />
      <TextField label="Delta DNA" source="deltaDnaProjectId" />
      <TextField label="AdMob" source="adMobAppId" />
      <TextField label="AppLovin" source="appLovinAppId" />
      <TextField label="Unity" source="unityAppId" />
    </Datagrid>
  </List>
);

export default GameList;
