import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query unitys {
      unitys {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const unitys = res.data.unitys.map((unity) => ({
    ...unity,
    id: unity.gameSlug,
  }));
  const { total, page } = clientPagination({ list: unitys, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query unity($gameSlug: ID!) {
      unity(gameSlug: $gameSlug) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { unity } = res.data;
  return { data: { ...unity, id: unity.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation unityUpdate($gameSlug: ID!, $appId: ID!) {
      updateUnity(gameSlug: $gameSlug, unityData: { appId: $appId }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, appId: data.appId },
  });
  const { updateUnity: updateDDna } = res.data;
  return { data: { ...updateDDna, id: updateDDna.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation unityCreate($appId: ID!, $gameSlug: ID!) {
      createUnity(unityData: { appId: $appId, gameSlug: $gameSlug }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      appId: data.appId,
    },
  });
  const { createUnity: createDDna } = res.data;
  return { data: { ...createDDna, id: createDDna.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteUnity($gameSlug: ID!) {
      deleteUnity(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
