import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

const BotServiceList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="gameSlug" />
      <BooleanField source="enabled" />
      <BooleanField source="saveLastMessageSlot" />
    </Datagrid>
  </List>
);

export default BotServiceList;
