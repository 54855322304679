import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query adMobs {
      adMobs {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const adMobs = res.data.adMobs.map((adMob) => ({
    ...adMob,
    id: adMob.gameSlug,
  }));
  const { total, page } = clientPagination({ list: adMobs, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query adMob($gameSlug: ID!) {
      adMob(gameSlug: $gameSlug) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { adMob } = res.data;
  return { data: { ...adMob, id: adMob.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation adMobUpdate($gameSlug: ID!, $appId: ID!) {
      updateAdMob(gameSlug: $gameSlug, adMobData: { appId: $appId }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, appId: data.appId },
  });
  const { updateAdMob: updateDDna } = res.data;
  return { data: { ...updateDDna, id: updateDDna.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation adMobCreate($appId: ID!, $gameSlug: ID!) {
      createAdMob(adMobData: { appId: $appId, gameSlug: $gameSlug }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      appId: data.appId,
    },
  });
  const { createAdMob: createDDna } = res.data;
  return { data: { ...createDDna, id: createDDna.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteAdMob($gameSlug: ID!) {
      deleteAdMob(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
