import React from 'react';
import { ArrayInput, Edit, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin';

const FbInstantGameEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <TextInput label="App Id" source="appId" />
      <TextInput source="appSecret" />
      <TextInput source="namespace" />
      <TextInput source="pageAccessToken" />
      <TextInput label="Page Id" source="pageId" />
      <TextInput label="Data Source Property Id" source="dataSourcePropertyId" />
      <TextInput source="webhookVerifyToken" />
      <ArrayInput label="Marketing Account Id" source="marketingAccountId">
        <SimpleFormIterator inline>
          <TextInput label="Account Id" source="accountId" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default FbInstantGameEdit;
