import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query deltaDnas {
      deltaDnas {
        projectId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const deltaDnas = res.data.deltaDnas.map((deltaDna) => ({
    ...deltaDna,
    id: deltaDna.gameSlug,
  }));
  const { total, page } = clientPagination({ list: deltaDnas, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query deltaDna($gameSlug: ID!) {
      deltaDna(gameSlug: $gameSlug) {
        projectId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { deltaDna } = res.data;
  return { data: { ...deltaDna, id: deltaDna.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation deltaDnaUpdate($gameSlug: ID!, $projectId: ID!) {
      updateDeltaDna(gameSlug: $gameSlug, deltaDnaData: { projectId: $projectId }) {
        projectId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, projectId: data.projectId },
  });
  const { updateDeltaDna: updateDDna } = res.data;
  return { data: { ...updateDDna, id: updateDDna.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation deltaDnaCreate($projectId: ID!, $gameSlug: ID!) {
      createDeltaDna(deltaDnaData: { projectId: $projectId, gameSlug: $gameSlug }) {
        projectId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      projectId: data.projectId,
    },
  });
  const { createDeltaDna: createDDna } = res.data;
  return { data: { ...createDDna, id: createDDna.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteDeltaDna($gameSlug: ID!) {
      deleteDeltaDna(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
