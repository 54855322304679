import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query appLovins {
      appLovins {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const appLovins = res.data.appLovins.map((appLovin) => ({
    ...appLovin,
    id: appLovin.gameSlug,
  }));
  const { total, page } = clientPagination({ list: appLovins, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query appLovin($gameSlug: ID!) {
      appLovin(gameSlug: $gameSlug) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { appLovin } = res.data;
  return { data: { ...appLovin, id: appLovin.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation appLovinUpdate($gameSlug: ID!, $appId: ID!) {
      updateAppLovin(gameSlug: $gameSlug, appLovinData: { appId: $appId }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, appId: data.appId },
  });
  const { updateAppLovin: updateDDna } = res.data;
  return { data: { ...updateDDna, id: updateDDna.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation appLovinCreate($appId: ID!, $gameSlug: ID!) {
      createAppLovin(appLovinData: { appId: $appId, gameSlug: $gameSlug }) {
        appId
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      appId: data.appId,
    },
  });
  const { createAppLovin: createDDna } = res.data;
  return { data: { ...createDDna, id: createDDna.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteAppLovin($gameSlug: ID!) {
      deleteAppLovin(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
