import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query botServices {
      botServices {
        gameSlug
        enabled
        saveLastMessageSlot
      }
    }
  `;

  const res = await client.query({ query });
  const botServices = res.data.botServices.map((botService) => ({
    ...botService,
    id: botService.gameSlug,
  }));
  const { total, page } = clientPagination({ list: botServices, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query botService($gameSlug: ID!) {
      botService(gameSlug: $gameSlug) {
        gameSlug
        enabled
        saveLastMessageSlot
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { botService } = res.data;
  return { data: { ...botService, id: botService.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation updateBotService($gameSlug: ID!, $enabled: Boolean!, $saveLastMessageSlot: Boolean) {
      updateBotService(
        gameSlug: $gameSlug
        botServiceData: { enabled: $enabled, saveLastMessageSlot: $saveLastMessageSlot }
      ) {
        gameSlug
        enabled
        saveLastMessageSlot
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
      enabled: data.enabled,
      saveLastMessageSlot: data.saveLastMessageSlot,
    },
  });
  const { updateBotService } = res.data;
  return { data: { ...updateBotService, id: updateBotService.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation createBotService($gameSlug: ID!, $enabled: Boolean!, $saveLastMessageSlot: Boolean) {
      createBotService(
        botServiceData: {
          gameSlug: $gameSlug
          enabled: $enabled
          saveLastMessageSlot: $saveLastMessageSlot
        }
      ) {
        gameSlug
        enabled
        saveLastMessageSlot
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      enabled: data.enabled ?? false,
      saveLastMessageSlot: data.saveLastMessageSlot ?? false,
    },
  });
  const { createBotService } = res.data;
  return { data: { ...createBotService, id: createBotService.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteBotService($gameSlug: ID!) {
      deleteBotService(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
