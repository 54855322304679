import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const AdMobList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="appId" />
      <TextField source="gameSlug" />
    </Datagrid>
  </List>
);

export default AdMobList;
