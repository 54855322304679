import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query gameBackends {
      gameBackends {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const gameBackends = res.data.gameBackends.map((gameBackend) => ({
    ...gameBackend,
    id: gameBackend.gameSlug,
  }));
  const { total, page } = clientPagination({ list: gameBackends, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query gameBackend($gameSlug: ID!) {
      gameBackend(gameSlug: $gameSlug) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { gameBackend } = res.data;
  return { data: { ...gameBackend, id: gameBackend.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation gbUpdate($gameSlug: ID!, $clusterId: ID!, $enabled: Boolean!) {
      updateGameBackend(
        gameSlug: $gameSlug
        gameBackendData: { clusterId: $clusterId, enabled: $enabled }
      ) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, enabled: data.enabled, clusterId: data.clusterId },
  });
  const { updateGameBackend: updateGB } = res.data;
  return { data: { ...updateGB, id: updateGB.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation gbCreate($clusterId: ID!, $enabled: Boolean!, $gameSlug: ID!) {
      createGameBackend(
        gameBackendData: { clusterId: $clusterId, enabled: $enabled, gameSlug: $gameSlug }
      ) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      clusterId: data.clusterId,
      enabled: data.enabled ?? false,
    },
  });
  const { createGameBackend: createGB } = res.data;
  return { data: { ...createGB, id: createGB.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteGb($gameSlug: ID!) {
      deleteGameBackend(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
