import React from 'react';
import { Create, SimpleForm, BooleanInput, ReferenceInput, SelectInput } from 'react-admin';

const BotServiceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="gameSlug" reference="game" perPage={999}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <BooleanInput source="enabled" />
      <BooleanInput source="saveLastMessageSlot" />
    </SimpleForm>
  </Create>
);

export default BotServiceCreate;
