import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const DeltaDnaEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <TextInput label="Project Id" source="projectId" />
    </SimpleForm>
  </Edit>
);

export default DeltaDnaEdit;
