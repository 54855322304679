import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const AdMobEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <TextInput label="App Id" source="appId" />
    </SimpleForm>
  </Edit>
);

export default AdMobEdit;
