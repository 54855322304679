import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const GameBackendEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="gameSlug" />
      <TextInput label="Cluster Id" source="clusterId" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export default GameBackendEdit;
