import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query splitTests {
      splitTests {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.query({ query });
  const splitTests = res.data.splitTests.map((splitTest) => ({
    ...splitTest,
    id: splitTest.gameSlug,
  }));
  const { total, page } = clientPagination({ list: splitTests, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query splitTest($gameSlug: ID!) {
      splitTest(gameSlug: $gameSlug) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { splitTest } = res.data;
  return { data: { ...splitTest, id: splitTest.gameSlug } };
}

async function update({ id, data }) {
  const query = gql`
    mutation stUpdate($gameSlug: ID!, $clusterId: ID!, $enabled: Boolean!) {
      updateSplitTest(
        gameSlug: $gameSlug
        splitTestData: { clusterId: $clusterId, enabled: $enabled }
      ) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { gameSlug: id, enabled: data.enabled, clusterId: data.clusterId },
  });
  const { updateSplitTest: updateST } = res.data;
  return { data: { ...updateST, id: updateST.gameSlug } };
}

async function create({ data }) {
  const query = gql`
    mutation stCreate($clusterId: ID!, $enabled: Boolean!, $gameSlug: ID!) {
      createSplitTest(
        splitTestData: { clusterId: $clusterId, enabled: $enabled, gameSlug: $gameSlug }
      ) {
        clusterId
        enabled
        gameSlug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      gameSlug: data.gameSlug,
      clusterId: data.clusterId,
      enabled: data.enabled ?? false,
    },
  });
  const { createSplitTest: createST } = res.data;
  return { data: { ...createST, id: createST.gameSlug } };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteSt($gameSlug: ID!) {
      deleteSplitTest(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
