import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import axios from 'axios';

import App from './App';
import NoAccess from './components/NoAccess';
import kcConfig from './keycloak.json';

// eslint-disable-next-line react/no-render-return-value
const render = (Component) => ReactDOM.render(<Component />, document.getElementById('root'));

const kc = new Keycloak(kcConfig);
kc.init({ checkLoginIframe: false, onLoad: 'login-required' })
  .then((authenticated) => {
    const hasRoles = kc.tokenParsed?.client_roles?.length > 0;
    if (authenticated && hasRoles) {
      axios.interceptors.request.use((config) => {
        return new Promise((resolve, reject) =>
          kc
            .updateToken(30)
            .then(() => {
              try {
                const configCopy = { ...config };
                configCopy.headers.Authorization = `Bearer ${kc.token}`;
                resolve(configCopy);
              } catch (e) {
                reject(e);
              }
            })
            .catch((e) => reject(e))
        );
      });

      return render(App);
    }
    return render(NoAccess);
  })
  .catch((error) => {
    // eslint-disable-next-line no-param-reassign
    error.message = `Failed to initialize: ${error.message}`;
    throw error;
  });
