import React from 'react';
import { List, Datagrid, TextField, ArrayField, SingleFieldList, ChipField } from 'react-admin';

const FbInstantgameList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="gameSlug" />
      <TextField label="App Id" source="appId" />
      <TextField source="appSecret" />
      <TextField source="namespace" />
      <TextField source="pageAccessToken" />
      <TextField label="Page Id" source="pageId" />
      <TextField label="Data Source Property Id" source="dataSourcePropertyId" />
      <TextField source="webhookVerifyToken" />
      <ArrayField label="Marketing Account Id" source="marketingAccountId">
        <SingleFieldList>
          <ChipField source="accountId" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default FbInstantgameList;
