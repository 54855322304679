import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const GameCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="slug" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default GameCreate;
